@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.user-message {
  width: 20em;
  float: right;
  text-align: center;
}

.tableHead {
  background-color: #39abbd;
  font-family: "Montserrat", sans-serif;
}

.textStyle {
  font-family: "Montserrat", sans-serif;
  color: white;
}

.rowHead {
  background-color: #f9f9f9;
}

.code11-font {
  font-family: "Montserrat";
  font-weight: bold;
}

.vacations-heading {
  display: flex;
  justify-content: center;
}
